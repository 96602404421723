<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppHeaderV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        header: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        subheader: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        message: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        icon: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotCounter: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotHeaderInfo: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotVisibleRules: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        promoCode: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        contentColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        backgroundColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        showMoreDeeplink: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        showMoreDeeplinkInputTitle: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        shouldForceLightTheme: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        shouldForceDarkTheme: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        tag: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                icon: {
                    hasImageTheme: true,
                },

                contentColor: {
                    hasColorTheme: true,
                },

                backgroundColor: {
                    hasColorTheme: true,
                },

                showMoreDeeplink: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    header: '',
                },

                slots: {
                    counter: '',
                    headerInfo: '',
                    visibleRules: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotCounter: this.slotCounter
                    ? JSON.parse(this.slotCounter)
                    : '',

                slotHeaderInfo: this.slotHeaderInfo
                    ? JSON.parse(this.slotHeaderInfo)
                    : '',

                slotVisibleRules: this.slotVisibleRules
                    ? JSON.parse(this.slotVisibleRules)
                    : '',
            };
        },
    },
};
</script>
